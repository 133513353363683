import { Glasses, ListTodo } from "lucide-react";
import { TypewriterEffect } from "./typewriter-effect";

const words = [
  {
    text: "Buying",
  },
  {
    text: "&",
  },
  {
    text: "Selling",
  },
  {
    text: "Bulk",
    className: "text-primary",
  },
  {
    text: "Wine",
    className: "text-primary",
  },
  {
    text: "Made",
  },
  {
    text: "Easy!",
  },
];

const How = () => {
  return (
    <div className="flex flex-col items-center justify-left px-12 gap-16 mb-32">
      <TypewriterEffect words={words} />
      <div className="grid md:grid-cols-2 gap-12 max-w-7xl">
        <div className=" flex flex-col">
          <div className="flex flex-row items-center gap-4">
            <Glasses size={48} color="red" />
            <h3 className="text-3xl font-bold">Browse Available Wines</h3>
          </div>
          <p className="text-lg">
            Sellers upload wines they want to sell and buyers can search/filter
            for specific wines to request samples.
          </p>
          <img
            className="drop-shadow-lg"
            src="/wines-available.png"
            alt="Wines For Sale Screenshot"
          ></img>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-4">
            <ListTodo size={48} color="red" />
            <h3 className="text-3xl font-bold">Create Wine Request</h3>
          </div>
          <p className="text-lg">
            If the wine you are looking for is not available, create a wine
            request and we will notify 200+ sellers of your request.
          </p>
          <img
            className="drop-shadow-lg"
            src="/request-wine.png"
            alt="Request Wine Screenshot"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default How;
