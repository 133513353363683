import { Button } from "@/components/ui/button";
import { getSupabaseWithSessionAndHeaders } from "@/lib/supabase.server";
import {
  json,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";

import CommissionExplainer from "@/components/commission-explainer";
import CTA from "@/components/cta";
import FAQ from "@/components/faq";
import Footer from "@/components/footer";
import Hero from "@/components/hero";
import How from "@/components/how";
import { Logo } from "@/components/logo";
import ScrollToTopButton from "@/components/scroll-top";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { headers, serverSession } = await getSupabaseWithSessionAndHeaders({
    request,
  });

  /*if (serverSession) {
    return redirect("/requests", { headers });
  }*/

  return json({ success: true, serverSession }, { headers });
};

export const meta: MetaFunction = () => {
  return [
    { title: "Daily Bulk Wine" },
    {
      name: "Connecting Bulk Wine Buyers & Sellers",
      content: "Welcome to Daily Bulk Wine!",
    },
  ];
};

export default function Index() {
  const { serverSession } = useLoaderData<typeof loader>();

  return (
    <section className="w-full bg-white min-h-screen flex flex-col">
      <nav className=" bg-white z-50 flex w-full items-center justify-center md:justify-between p-4 border-b border-zinc-200 flex-wrap md:flex-nowrap gap-3">
        <Logo size="large" />
        {serverSession ? (
          <Button className="">
            <Link to="/requests">DASHBOARD</Link>
          </Button>
        ) : (
          <Button className="">
            <Link to="/login">LOGIN</Link>
          </Button>
        )}
      </nav>
      <Hero />
      <How />
      <CommissionExplainer />
      <FAQ />
      <CTA />
      <ScrollToTopButton />

      <Footer />
    </section>
  );
}
