import Globe from "@/assets/global.svg";

const Hero = () => {
  return (
    <div className="md:flex justify-center items-center flex-1 pt-16 md:pt-3 mb-32">
      <div className="flex flex-col items-center space-y-4 text-center p-4 md:w-1/2">
        <h1 className="text-6xl md:text-8xl font-bold tracking-tighter">
          Connecting{" "}
          <span className="font-extrabold text-primary">Bulk Wine</span> Buyers
          & Sellers
        </h1>
        <p className="text-gray-500 mt-2">
          Powered by{" "}
          <a href="https://dailywine.co.za">
            <span className="text-primary font-bold mt-2">The Daily Wine</span>
          </a>
        </p>
      </div>
      <div className="relative group overflow-hidden rounded-lg md:w-1/2 -mt-24">
        <img src={Globe} alt="Globe"></img>
      </div>
    </div>
  );
};

export default Hero;
