const Footer = () => {
  return (
    <footer className="overflow-hidden">
      <div className="px-8 md:px-12 py-12 max-w-2xl mx-auto">
        <div className="px-8 md:px-12 py-6 max-w-2xl mx-auto">
          <nav className="flex flex-col lg:flex-row gap-8">
            <p className="text-sm text-black">
              © {new Date().getFullYear()} Daily Bulk Wine. All rights reserved.
              Daily Bulk Wine is a{" "}
              <a
                href="https://dailywine.co.za"
                className="text-sm text-red-500 hover:underline"
              >
                The Daily Wine
              </a>{" "}
              subsidiary.
            </p>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
