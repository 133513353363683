import { Calculator, SlidersHorizontal } from "lucide-react";
import { useForm } from "react-hook-form";
import { Commission } from "./commission";
import { Input } from "./ui/input";
import { Label } from "./ui/label";

const CommissionExplainer = () => {
  const form = useForm();
  return (
    <div
      id="commission"
      className="flex flex-col items-center justify-left px-12 gap-12 mb-32"
    >
      <div className="flex flex-col gap-6 max-w-3xl">
        <h2 className="text-5xl font-bold text-center">
          What is the commission structure?
        </h2>
        <p className="text-xl">
          Commission is calculated on a sliding scale based on the total volume
          of bulk wine sold. The more wine you sell, the lower the commission
          rate.
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-5 max-w-7xl">
        <div className=" flex flex-col">
          <div className="flex flex-row items-center gap-4">
            <SlidersHorizontal size={48} color="red" />
            <h3 className="text-3xl font-bold">Sliding Scale</h3>
          </div>
          <ul className="my-6">
            <li>Liters ≤ 30 000 (4.00%)</li>
            <li>30 000 &lt; Liters ≤ 250 000 (3.50%)</li>
            <li>250 000 &lt; Liters ≤ 500 000 (3.00%)</li>
            <li>500 000 &lt; Liters ≤ 1 000 000 (2.50%)</li>
            <li>Liters &gt; 1 000 000 (2.00%)</li>
          </ul>
        </div>

        <div className="flex flex-col gap-6 border p-6 rounded-md drop-shadow-md bg-white">
          <div className="flex flex-row items-center gap-4">
            <Calculator size={48} color="red" />
            <h3 className="text-3xl font-bold">Commission Calculator</h3>
          </div>
          <div>
            <Label className="text-lg font-bold">Volume</Label>
            <Input
              type="number"
              defaultValue={100000}
              {...form.register("volume")}
            />
          </div>
          <div>
            <Label className="text-lg font-bold">Price</Label>
            <Input
              type="number"
              defaultValue={12}
              {...form.register("price")}
            />
          </div>
          <Commission
            price={form.watch("price")}
            volume={form.watch("volume")}
          />
        </div>
      </div>
    </div>
  );
};

export default CommissionExplainer;
