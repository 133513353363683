import { Link } from "@remix-run/react";
import { Button } from "./ui/button";

const CTA = () => {
  return (
    <div className="flex flex-col items-center justify-left px-12 gap-12 mb-32">
      <div className="flex flex-col gap-6 max-w-3xl items-center">
        <h2 className="text-5xl font-bold text-center">
          Are you ready to get started?
        </h2>
        <p className="text-xl text-center">
          Daily Bulk Wine is the easiest way to buy and sell bulk wine. But we
          know it can be intimidating working on a new platform. We are here to
          help you every step of the way. If you have any questions, please send
          us an email at{" "}
          <a
            className="text-primary underline"
            href="mailto:admin@dailybulkwine.com"
          >
            admin@dailybulkwine.com
          </a>
        </p>
        <Link to="/requests">
          <Button size="lg">Get Started</Button>
        </Link>
      </div>
    </div>
  );
};

export default CTA;
