import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

const FAQ = () => {
  return (
    <div
      id="commission"
      className="px-12 gap-12 mb-32 w-full flex flex-col content-center"
    >
      <div className="flex flex-col gap-6 justify-center">
        <h2 className="text-5xl font-bold text-center">
          Frequently Asked Questions
        </h2>
        <p className="text-xl text-center">
          Here are some common questions we get about our platform.
        </p>
      </div>
      <div className="flex flex-row justify-center">
        <Accordion className="w-full max-w-4xl" type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger>Who pays the commission?</AccordionTrigger>
            <AccordionContent>
              All commissions are paid by the seller. The commission structure
              can be seen{" "}
              <a className="underline" href="/#commission">
                here
              </a>
              .
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Where is Daily Bulk Wine based?</AccordionTrigger>
            <AccordionContent className="w-max-[300px]">
              We are based on the premises of Koelenhof Wine Cellar, R304,
              Stellenbosch. We are not affiliated with Koelenhof Wine Cellar, we
              simply operate from their premises.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>
              Do I know who the buyer/seller is?
            </AccordionTrigger>
            <AccordionContent>
              Buyers & sellers are kept anonymous until a deal is agreed upon.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>
              After a deal is made, how do I get paid?
            </AccordionTrigger>
            <AccordionContent>
              We sign contracts with both parties and the payment flows through
              us.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>
              Are there any costs involved in listing or requesting wines?
            </AccordionTrigger>
            <AccordionContent>
              No, there are no costs involved in listing or requesting wines.
              The only time a cost is involved is when a deal is made, and the
              seller pays the commission.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger>What do I need to register?</AccordionTrigger>
            <AccordionContent>
              All you need to register is an email address and a password.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-7">
            <AccordionTrigger>What are the payment terms?</AccordionTrigger>
            <AccordionContent>
              Payment terms are part of the negotiations as normal.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
